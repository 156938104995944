import React from 'react';

import BasicHeader from '../components/page-sections/basic-header';
import BigCta from '../components/page-sections/big-cta';
import CheckPoints from '../components/page-sections/check-points';
import LonelyTitle from '../components/page-sections/lonely-title';
import TopicDescription from '../components/page-sections/topic-description';

/**
 * renderSection will return the proper section base on the templateName.
 * You will find a warning is no matching template was found.
 *
 * @param {string} templateName Name of template
 * @param {object} options Optional option needed by some template
 * @param {object} data Props to pass to the template component
 * @param {number} index Index
 * @param {boolean} isSectionPresentByLang Check is this section have content
 * for a specific language
 * @return {object} Section component
 */
// eslint-disable-next-line complexity
export const renderSection = (
  templateName,
  options,
  data,
  index,
  isSectionPresentByLang = true,
) => {
  if (!isSectionPresentByLang) {
    return null;
  }

  switch (templateName) {
    case 'BigCTA':
      return <BigCta {...data} key={index} theme={options.theme} />;
    case 'BasicHeader':
      return <BasicHeader {...data} key={index} />;
    case 'CheckPoints':
      return <CheckPoints {...data} key={index} />;
    case 'LonelyTitle':
      return <LonelyTitle {...data} key={index} />;
    case 'TopicDescription':
      return (
        <TopicDescription
          {...data}
          isReversed={options.isReversed}
          key={index}
        />
      );
    default:
      throw new Error(`Section not rendered :'${templateName}`);
  }
};

/**
 * getPropsByLang will return all props needed by a section based on the
 * language.
 * Some data structure contain information which are not translate so they are
 * out of the default `content.lang` key, this function will take care of gather
 * every data as props.
 *
 * @param {object} section Section props
 * @param {string} lang Language
 * @return {object} Props by language
 */
export const getPropsByLang = (section, lang) => {
  const translatedContent = section.content[lang];

  if (section && section.icon) {
    translatedContent.icon = section.icon;
  }
  if (section && section.number) {
    translatedContent.number = section.number;
  }

  return {
    ...translatedContent,
  };
};

/**
 * getPictureProps will gather all picture names and return them.
 * For now we have only one use case where only one picture is returned.
 *
 * @param {object} section Section props
 * @param {object} pictures All fetched picture as array
 * @return {object} Pictures props if exist
 */
export const getPicturesProps = (section, pictures, pageName = null) => {
  if (!section.picture || !pictures) {
    return {};
  }

  let pictureToInject;
  if (Array.isArray(pictures)) {
    pictureToInject = pictures.find(
      (el) => el.fluid.originalName === section.picture,
    );
  } else {
    Object.keys(pictures).forEach((el) => {
      if (el === pageName) {
        pictureToInject = pictures[pageName].find(
          (pictureEl) => pictureEl.fluid.originalName === section.picture,
        );
      }
    });
  }

  if (pictureToInject) {
    return {
      picture: pictureToInject,
    };
  }

  return {};
};
