import React from 'react';
import PropTypes from 'prop-types';

import { Container, LeftContainer, RightContainer } from './style';
import { Title, P, Pane, Section, Wrapper } from '../../ui';
import Image from '../../image';

const TopicDescription = ({ title, description, picture, isReversed }) => (
  <Section>
    <Wrapper>
      <Container>
        <LeftContainer isReversed={isReversed}>
          <Pane direction="column" margin="0 auto" maxWidth="400px">
            <Title align="inherit" level={2} size="xxl">
              {title}
            </Title>
            <Pane margin="30px 0">
              <P
                align="inherit"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Pane>
          </Pane>
        </LeftContainer>
        <RightContainer isReversed={isReversed}>
          {picture ? (
            <Image
              hasGradient
              gradientOrientation={isReversed ? 'NO' : 'SE'}
              hasOverflowHidden={false}
              objectFit="cover"
              picture={picture}
            />
          ) : null}
        </RightContainer>
      </Container>
    </Wrapper>
  </Section>
);

TopicDescription.propTypes = {
  description: PropTypes.string,
  isReversed: PropTypes.bool,
  picture: PropTypes.shape({
    childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
  }),
  title: PropTypes.string,
};

TopicDescription.defaultProps = {
  description: undefined,
  isReversed: undefined,
  picture: undefined,
  title: undefined,
};

export default TopicDescription;
