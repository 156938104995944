import styled from 'styled-components';

import { MediaQuery, BreakPoint } from '../../../utils/constants';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${BreakPoint.XL};
`;

export const LeftContainer = styled.div`
  min-width: 400px;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  position: sticky;
  top: 110px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 150px;
  padding-top: 100px;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    padding-left: 0;
  }
`;

export const CheckPointsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;

  @media ${MediaQuery.MOBILE} {
    flex-direction: column;
  }
`;

export const CheckPointCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 200px;
  margin: 0 50px 30px 0;
  flex-basis: 100%;

  @media ${MediaQuery.MOBILE} {
    width: 100%;
    max-width: 250px;
    margin: 0 auto 30px auto;
  }
`;
