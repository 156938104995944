import styled from 'styled-components';

import { MediaQuery } from '../../../utils/constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 700px;
  margin: 100px 0;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    margin: 50px 0;
    text-align: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 700px;
  height: 700px;
  overflow: hidden;
  position: absolute;
  border-radius: 100%;
  right: -50px;
  top: -200px;
  z-index: -1;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    display: none;
  }
`;
