import React from 'react';
import PropTypes from 'prop-types';
import { Color } from '../../../utils/constants';

import {
  LeftContainer,
  RightContainer,
  CheckPointsContainer,
  CheckPointCard,
  ImageContainer,
  Wrapper,
} from './style';
import { Title, Pane, Section, P, Icon, Bubble } from '../../ui';
import Image from '../../image';

const CategoriesHeader = ({ title, checkPoints, picture }) => (
  <Section>
    <Wrapper>
      <LeftContainer>
        <ImageContainer>
          <Image hasGradient picture={picture} />
        </ImageContainer>
      </LeftContainer>
      <RightContainer>
        <Title align="inherit" size="xxxl">
          {title}
        </Title>
        <CheckPointsContainer>
          {checkPoints.map((checkPoint) => (
            <CheckPointCard key={checkPoint}>
              <Pane margin="0 0 20px 0">
                <Bubble
                  backgroundColor="#F6F7FA"
                  color={Color.PORTAGE}
                  hasBorder
                  size={40}
                >
                  <Icon name="check" />
                </Bubble>
              </Pane>
              <P>{checkPoint}</P>
            </CheckPointCard>
          ))}
        </CheckPointsContainer>
      </RightContainer>
    </Wrapper>
  </Section>
);

CategoriesHeader.propTypes = {
  checkPoints: PropTypes.arrayOf(PropTypes.string),
  picture: PropTypes.shape({
    childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
  }),
  title: PropTypes.string,
};

CategoriesHeader.defaultProps = {
  checkPoints: undefined,
  picture: undefined,
  title: undefined,
};

export default CategoriesHeader;
