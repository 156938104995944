import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image/withIEPolyfill';
import throttle from 'lodash.throttle';

import { Wrapper, Container, ParallaxContainer } from './style';
import { ImageGradient } from '../ui';

const getTopPosition = (parallaxId, defaultTop = 0) => {
  if (typeof window === 'undefined') {
    return 0;
  }

  const windowHeight = window.innerHeight;
  const el = document.getElementById(`parallax-${parallaxId}`);
  const elementPositionY = el ? el.getBoundingClientRect().top : defaultTop;

  return (windowHeight - elementPositionY) * 0.2;
};

const Image = ({
  alt,
  children,
  hasGradient,
  hasOverflowHidden,
  hasRadius,
  objectFit,
  objectPosition,
  isBackground,
  picture,
  /**
   * Represents the top offset by default for parallax script. Some element if
   * they are higher than the window top create a positioning issue. Fixable
   * by setting a default top before mounting to get the right position before
   * mounting.
   */
  parallaxBasePositionY,
  /**
   * We need a specific ID for parallax effect, if parallax id is present
   * the effect in ON, else if OFF
   */
  parallaxId,
  isTransparent,
  gradientOrientation,
}) => {
  /**
   * Can not use the hook `useRef`, instead we target element with plain JS
   * This solution is not working:
   * https://stackoverflow.com/questions/58051345/react-hook-useref-not-working-with-styled-components-and-typescript
   */
  const [top, setTop] = useState(
    getTopPosition(parallaxId, parallaxBasePositionY),
  );
  const onScroll = throttle(() => setTop(getTopPosition(parallaxId)), 10);

  useEffect(() => {
    if (parallaxId && window) {
      window.addEventListener('scroll', onScroll);
    }
    return () =>
      parallaxId && window && window.removeEventListener('scroll', onScroll);
  });

  return (
    <Wrapper hasOverflowHidden={hasOverflowHidden}>
      {picture ? (
        <Container hasRadius={hasRadius} objectFit={objectFit}>
          <ParallaxContainer
            id={`parallax-${parallaxId}`}
            isParallaxActive={!!parallaxId}
            isTransparent={isTransparent}
            /**
             * - If parallax, we compensate the CSS position rules to position in
             * the middle including the top value based on the scroll position.
             * - Else we just position the content in the middle.
             */
            style={{ top: parallaxId ? `calc(20% + ${top}px) ` : `50%` }}
          >
            {isBackground ? (
              <BackgroundImage
                fixed={picture.fixed || undefined}
                fluid={picture.fluid || undefined}
                objectFit={objectFit}
                style={{ width: '100%', height: '100%' }}
                Tag="div"
              />
            ) : (
              <Img
                alt={alt}
                fixed={picture.fixed || undefined}
                fluid={picture.fluid || undefined}
                objectFit={objectFit}
                objectPosition={objectPosition}
                style={{ width: '100%', height: '100%' }}
              />
            )}
          </ParallaxContainer>
          {hasGradient ? (
            <ImageGradient orientation={gradientOrientation} />
          ) : null}
          {children}
        </Container>
      ) : null}
    </Wrapper>
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  objectPosition: PropTypes.shape(),
  children: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  gradientOrientation: PropTypes.oneOf(['SW', 'SE']),
  hasGradient: PropTypes.bool,
  hasOverflowHidden: PropTypes.bool,
  hasRadius: PropTypes.bool,
  isBackground: PropTypes.bool,
  isTransparent: PropTypes.bool,
  objectFit: PropTypes.oneOf(['cover', 'contain']),
  parallaxBasePositionY: PropTypes.number,
  parallaxId: PropTypes.string,
  picture: PropTypes.shape({
    fixed: PropTypes.shape({}),
    fluid: PropTypes.shape({}),
  }),
};

Image.defaultProps = {
  alt: undefined,
  objectPosition: undefined,
  children: undefined,
  hasGradient: undefined,
  isBackground: undefined,
  isTransparent: undefined,
  parallaxBasePositionY: undefined,
  parallaxId: undefined,
  picture: undefined,
};

Image.defaultProps = {
  hasOverflowHidden: true,
  objectFit: 'cover',
  hasRadius: true,
  gradientOrientation: 'SW',
};

export default Image;
