import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled(({ hasOverflowHidden, ...props }) => (
  <div {...props} />
))`
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 100%;
  overflow: ${({ hasOverflowHidden }) =>
    hasOverflowHidden ? 'hidden' : 'visible'};
`;

export const Container = styled(({ hasRadius, objectFit, ...props }) => (
  <div {...props} />
))`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: ${({ hasRadius }) => (hasRadius ? '100%' : 'none')};
`;

Container.defaultProps = {
  objectFit: 'cover',
};

export const ParallaxContainer = styled(
  ({ isParallaxActive, isTransparent, ...props }) => <div {...props} />,
)`
  width: ${({ isParallaxActive }) => (isParallaxActive ? '120%' : '100%')};
  height: ${({ isParallaxActive }) => (isParallaxActive ? '120%' : '100%')};
  left: ${({ isParallaxActive }) => (isParallaxActive ? '-10%' : '0')};
  transform: translateY(-50%);
  position: absolute;
  opacity: ${({ isTransparent }) => (isTransparent ? '0.5' : 1)};
`;
