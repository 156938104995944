import React from 'react';
import PropTypes from 'prop-types';

import { ContentWrapper, ImageContainer, Wrapper } from './style';
import { Title, Pane, Button, Section, P } from '../../ui';
import Image from '../../image';
import Cta from '../../CTA';

const CategoriesHeader = ({ title, link, intro, picture }) => (
  <Section overflow="hidden" position="relative">
    <Wrapper>
      <ContentWrapper>
        <Title align="inherit" size="xxxxl">
          {title}
        </Title>
        <Pane margin="20px 0" maxWidth="460px">
          <P align="inherit" dangerouslySetInnerHTML={{ __html: intro }} />
        </Pane>
        {link ? (
          <Button is={Cta} link={link}>
            {link.label}
          </Button>
        ) : null}
      </ContentWrapper>
      <ImageContainer>
        <Image
          alt={title}
          gradientOrientation="SE"
          hasGradient
          isTransparent
          // parallaxBasePositionY={-140} // Based on ImageContainer top position
          // parallaxId='categories-header'
          picture={picture}
        />
      </ImageContainer>
    </Wrapper>
  </Section>
);

CategoriesHeader.propTypes = {
  intro: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }),
  picture: PropTypes.shape({
    childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
  }),
  title: PropTypes.string,
};

CategoriesHeader.defaultProps = {
  intro: undefined,
  link: undefined,
  picture: undefined,
  title: undefined,
};

export default CategoriesHeader;
