import React from 'react';
import styled from 'styled-components';

import { MediaQuery } from '../../../utils/constants';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 50px 0;
  padding: 50px 0;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    flex-direction: column;
    padding: 0;
  }
`;

export const LeftContainer = styled(({ isReversed, ...props }) => (
  <div {...props} />
))`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  order: ${({ isReversed }) => (isReversed ? 1 : 0)};
  padding: 50px;
  width: 50%;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    width: 100%;
    padding: 20px 0;
    order: 1;
    align-items: center;
    text-align: center;
  }
`;

export const RightContainer = styled(({ isReversed, ...props }) => (
  <div {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: center;
  order: ${({ isReversed }) => (isReversed ? 0 : 1)};
  width: 50%;
  max-height: 500px;

  @media ${MediaQuery.TABLET_AND_DOWN} {
    width: 70%;
    margin: auto;
    order: 0;
  }
  @media ${MediaQuery.MOBILE} {
    width: 100%;
  }
`;
