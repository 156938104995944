import React from 'react';
import PropTypes from 'prop-types';

import { Title, Section, Wrapper, Pane } from '../../ui';

const LonelyTitle = ({ title }) => (
  <Section>
    <Wrapper>
      <Pane margin="100px 0 10px 0" maxWidth="600px">
        <Title size="xxxl">{title}</Title>
      </Pane>
    </Wrapper>
  </Section>
);

LonelyTitle.propTypes = {
  title: PropTypes.string,
};

LonelyTitle.defaultProps = {
  title: undefined,
};

export default LonelyTitle;
