import classNames from 'classnames/bind';
import React from 'react';
import { graphql } from 'gatsby';
import PropType from 'prop-types';

import Layout from 'components/Layout';
import {
  getPicturesProps,
  getPropsByLang,
  renderSection,
} from 'wooclap/utils/helpers';

import styles from './Pilot.module.scss';

const cx = classNames.bind(styles);

export const query = graphql`
  query {
    pilot: allFile(filter: { relativeDirectory: { eq: "images/pilot" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 700, quality: 90) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`;

const PilotPage = ({ data, pageContext }) => {
  const pictures = data.pilot.edges.map((el) => el.node.childImageSharp);
  return (
    <Layout
      lang={pageContext.lang}
      t={pageContext.layout}
      urls={pageContext.urls}
    >
      <div className={cx('container')}>
        {pageContext.sections.map((section, index) =>
          renderSection(
            section.template,
            {
              isReversed: section.orientation === 'reversed',
              animation: section.animation,
              theme: section.theme,
            },
            {
              ...getPropsByLang(section, pageContext.lang),
              ...getPicturesProps(section, pictures, pageContext.name),
            },
            index,
            !!section.content[pageContext.lang],
          ),
        )}
      </div>
    </Layout>
  );
};

PilotPage.propTypes = {
  data: PropType.shape({
    pilot: PropType.shape({
      edges: PropType.arrayOf(
        PropType.shape({
          node: PropType.shape({}),
        }),
      ),
    }),
  }),
  pageContext: PropType.object.isRequired,
};

PilotPage.defaultProps = {
  data: undefined,
};

export default PilotPage;
